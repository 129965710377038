import {formatUnits } from "viem";
import React from "react";
import {TokenDropdown, tokenDropdownOption} from "@/components/TokenDropdown";
import {TokenSymbol} from "@/components/Perps/TokenSymbol";
import {TokenInfo} from "@/util/tokens";

export interface Props {
  inputValue: string | undefined;
  setInputValue: (value: string | undefined) => void;
  tokens: TokenInfo[];
  selectedToken: TokenInfo;
  onTokenSelected: (token: TokenInfo) => void;
  placeholder?: string;
  title?: any;
  currentBalance?: bigint;
}

export const TokenInput = (
  { inputValue, setInputValue, tokens, selectedToken, onTokenSelected, placeholder = "Amount", title, currentBalance }: Props
) => {
  const currentBalanceRaw = Number(formatUnits(currentBalance || 0n, selectedToken.decimals));
  return (
    <div className="standard-frame flex flex-row gap-2 justify-between py-1 md:py-4 px-2 text-xl">
      <div className="flex flex-col gap-1 justify-center">
        {title && <div className="text-neutral-content text-xs">{title}</div>}
        <input
          type="number"
          className="unstyled-input text-lg md:text-2xl text-white truncate w-[180px]"
          placeholder={placeholder}
          maxLength={7}
          min={0}
          value={inputValue ?? undefined}
          onChange={(e) => {
            const value = e.target.value;
            if (value) {
              setInputValue(value);
            } else {
              setInputValue(undefined);
            }
          }}
        />
      </div>
      <div className="flex flex-col items-end gap-2">
        <div></div>
        {tokens.length > 1
          ? <TokenDropdown
            options={tokens.map(t => tokenDropdownOption(t, t.symbol, t.imageUrl))}
            onChange={onTokenSelected}
            selected={selectedToken}
          />
          : <TokenSymbol
            symbol={tokens[0].symbol}
            imageUrl={tokens[0].imageUrl}
            displayTokenSelect={false}
          />
        }
        {
          currentBalance !== undefined ?
            <span className="text-xs font-light text-neutral-content">
              Balance: {currentBalanceRaw.toLocaleString([], {maximumFractionDigits: currentBalanceRaw < 1 ? 4 : 2})}&nbsp;
              <span className="text-call hover:underline cursor-pointer"
                    onClick={() => setInputValue(formatUnits(currentBalance, selectedToken.decimals))}>Max</span>
            </span> :
            <div></div>
        }
      </div>
    </div>
  )
}