import { Listbox } from "@headlessui/react";
import React from "react";
import classNames from "classnames";
import {twMerge} from "tailwind-merge";
import {IoChevronDown} from "react-icons/io5";
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {tokenConstants, TokenInfo} from "@/util/tokens";
import {formatUnits} from "viem";
import {AddressZero} from "@ethersproject/constants";
import {multicall} from "@wagmi/core";
import {config} from "config";
import {useQuery} from "@tanstack/react-query";
import {MulticallAbi} from "@/contract/MulticallAbi";
import {WasabiVaultAbi} from "@/contract/WasabiVaultAbi";
import {ERC20Abi} from "@/contract/ERC20Abi";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {isUsdToken} from "@/util/chainConstants";
import {CHAIN_ID} from "@/util/constants";
import {IoMdClose} from "react-icons/io";
import useId from "@/hooks/useId";
import {Tooltip as ReactTooltip} from "react-tooltip";

export function tokenDropdownOption(value: TokenInfo, label: string, icon?: string | React.ReactNode): TokenDropdownOption {
  return {value, label, icon};
}

export interface TokenDropdownOption {
  icon?: string | React.ReactNode;
  label: string;
  value: TokenInfo;
}

export const TokenDropdown = ({
  options,
  selected,
  onChange,
  className,
  itemClassName,
  optionsClassName,
  hideCaret = false,
  optionsImageClassName
}: {
  options: TokenDropdownOption[];
  selected: any;
  onChange: (value: any) => void;
  className?: string;
  itemClassName?: string;
  optionsClassName?: string;
  optionsImageClassName?: string;
  hideCaret?: boolean;
}) => {
  const selectedOption = options.find(o => o.value.address === selected.address) || options[0];
  const hasVaultOptions = options.find(o => o.value.isVault) != undefined;

  const { address } = useCurrentUser();
  const { ethPrice } = useEthPrice();

  const balanceQuery = useQuery({
    queryKey: [address, ...options.map(t => t.value.address)],
    enabled: !!address,
    refetchInterval: 10 * 1000, // 10 seconds
    queryFn: async () => await multicall(config, {
      contracts: options.map(t => {
        if (t.value.address === AddressZero) {
          return {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            abi: MulticallAbi,
            functionName: "getEthBalance",
            args: [address]
          }
        }
        if (t.value.isVault) {
          return {
            address: t.value.address,
            abi: WasabiVaultAbi,
            functionName: "maxWithdraw",
            args: [address]
          }
        }
        return {
          address: t.value.address,
          abi: ERC20Abi,
          functionName: "balanceOf",
          args: [address]
        }
      })
    }).then(r => {
      // @ts-ignore
      return r.map(d => BigInt(d.result))
    })
  })

  const renderSelected = () => {
    const isVault = selectedOption.value.isVault;

    let icon = selectedOption.icon;
    if (isVault && typeof icon === 'string') {
      icon = icon.replace(".svg", "_i.svg")
    }

    const id = useId();

    return <div className="flex flex-row gap-2 items-center justify-between">
      <span
        className="flex flex-row gap-2 items-center justify-start"
        data-for="tooltip"
        id={id}
      >
        {
          (selectedOption.icon || selectedOption.label === "ETH" || selectedOption.label === "WETH") ?
            (
              typeof icon === 'string'
                ? <img src={icon}
                       alt={selectedOption.label}
                       className={classNames("w-7 h-7", {
                         "rounded-full": !isVault
                       })}/>
                : icon
            ) :
            <div
              className="w-7 h-7 rounded-full text-[8px] glassborder flex items-center justify-center">{selectedOption.label.substring(0, 3)}</div>
        }
        <span className="text-neutral-content select-none text-base">{selectedOption.label}</span>
      </span>
      {
        isVault &&
          <ReactTooltip
              anchorSelect={`#${id}`}
              id={"tooltip_" + (id || "tradeWithVault_tooltip")}
              place="bottom"
              noArrow
              className="z-50 flex flex-col justify-content-start"
              style={{ backgroundColor: "#3b485f", color: "#98a2b3" }}
          >
              <p className="text-sm font-bold text-left">Trade Using Vault Deposits</p>
              <p className="text-xs text-neutral-content text-left">
                  Trade with your vault assets <u>without</u> losing your streak.
              </p>
          </ReactTooltip>
      }
    </div>
  }

  const renderOption = (option: TokenDropdownOption, balance = 0n) => {
    const isUsd = isUsdToken(option.value.address) || option.value.address === tokenConstants[CHAIN_ID].wUsd.address;
    const balNumber = Number(formatUnits(balance, option.value.decimals));
    const usdValue = isUsd ? balNumber : balNumber * (ethPrice || 0);

    return <div className="flex flex-row gap-2 items-center justify-between">
      <div className="flex flex-row gap-2 items-center justify-start">
        {
          (option.icon || option.label === "ETH" || option.label === "WETH") ?
            (
              typeof option.icon === 'string'
                ? <img src={option.icon as string}
                       alt={option.label}
                       className={twMerge("w-7 h-7 rounded-full", optionsImageClassName || "")}/>
                : option.icon
            ) :
            <div
              className="w-7 h-7 rounded-full text-[8px] glassborder flex items-center justify-center">{option.label.substring(0, 3)}</div>
        }
        <span className="text-neutral-content select-none text-base">{option.label}</span>
      </div>
      <div className="flex flex-col gap-1 items-end">
        <span className="text-xs">{
          balNumber.toLocaleString([], {
            maximumFractionDigits: balNumber < 1 ? 4 : 2
          })
        }
        </span>
        <span className="text-neutral-content text-xs">${
          usdValue.toLocaleString([], {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        }</span>
      </div>
    </div>
  }

  return (
    <Listbox
      value={options}
      onChange={(value) => {
        onChange(value);
      }}
    >
      <div className="relative">
        <Listbox.Button className={twMerge('w-max flex flex-row items-center justify-between gap-1 glassborder rounded-full px-2 py-1 cursor-pointer hover:bg-glass-focus', className || '')}>
          {renderSelected()}
          {!hideCaret && <span className="flex items-center">
            <IoChevronDown size={16} className="text-neutral-content"/>
          </span>}
        </Listbox.Button>
        <Listbox.Options
          className={twMerge('min-w-[350px] mt-1 absolute bg-main-bg whitespace-nowrap text-xs p-4 no-scrollbar z-20 standard-stack overflow-y-auto right-0 rounded-md border border-neutral-content/50', optionsClassName || '')}>
          <div className="flex flex-row justify-between">
            <div>Select from</div>
            <Listbox.Button><IoMdClose size={20} className="text-neutral-content"/></Listbox.Button>
          </div>
          <div className="glassborder divide-y divide-neutral-content/20 rounded-xl">
            {options.map((option, i) => (
              <Listbox.Option
                key={option.value.address}
                value={option.value}
                className={twMerge(classNames("hover:bg-glass hover:cursor-pointer p-2", {
                  "bg:glass-focus": option.value === selected,
                }), itemClassName || '')}
              >
                {renderOption(option, balanceQuery.data? balanceQuery.data[i] : 0n)}
              </Listbox.Option>
            ))}
          </div>
          {hasVaultOptions && <div
            className="flex flex-row gap-2 text-[11px] justify-center rounded-full p-2 mt-2 border !border-wasabigreen">
            <img src="/static/greenfire.png" alt="streak_comp" className="h-4"/>
            <span>Use vault deposits <u>without</u> interrupting your streak.</span>
          </div>}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};
